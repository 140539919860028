import './App.css'
import birdie from './birdie.JPG';

const App = () => {

    return (
      <main>
        <img src={birdie} alt="Birdie Parties" className="birdie-pic" />
      </main>
    )
}

export default App;
